import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Home from "./unit/Home";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/home" component={Home}/>
                <Route path="/" exact={true}>
                    <Redirect to="/home"/>
                </Route>
            </Switch>
        </Router>
    );
}



export default App;
